<template>
  <div class="foreign_partners">
    <b-button @click="modalShow = !modalShow">{{
      $t("add_partners.add_partners")
    }}</b-button>
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="addPartners">
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <input
          type="file"
          style="margin: 10px 0"
          accept="image/jpg, image/png, image/jpeg"
          class="input"
          required
          @change="uploadImage($event)"
        />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import env from "../../../env.json";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "login",
  components: {
    VueEditor,
    spinner,
  },
  props: {
    getPartners: { type: Function },
  },
  data() {
    return {
      modalShow: false,
      form: {
        show: false,
        image: null,
      },
      error: "",
      isLoading: false,
    };
  },
  methods: {
    uploadImage(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.form.image = image;
    },
    addPartners() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("show", this.form.show);
      formData.append("image", this.form.image);
      axios
        .post(`${env.host}/add/partners`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getPartners();
          }, 1000);
        })
        .catch((err) => {
          this.error = err;
          console.log(this.error);
        });
    },
    emptyValues() {
      this.form.show = false;
      this.form.image = null;
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
.input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-left: 10px;
  height: 40px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.foreign_partners {
  margin-bottom: 10px;
}
.text_editor {
  margin-bottom: 50px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
