<template>
  <div class="team_management">
    <AddForeignPartners :getPartners="getPartners" />
    <div class="main_div">
      <div
        class="box"
        style="height: 50px; color: white; background: #7e5493"
        v-for="item in header"
        :key="item.id"
      >
        <label for="">{{ item }} </label>
      </div>
    </div>
    <div v-if="!isLoading" class="testingScroll">
      <Container
        behaviour="contain"
        orientation="vertical"
        @drop="onDrop"
        :remove-on-drop-out="true"
        drag-class="dragging"
      >
        <Draggable v-for="partners in allPartners" :key="partners.id">
          <div class="main_div">
            <div class="box show_on_front">
              <i v-if="partners.show == true" class="fal fa-check-circle"></i>
              <i v-if="partners.show == false" class="fal fa-ban"></i>
            </div>
            <div class="box">
              <img
                style="height: 70px; width: 150px"
                :src="server_url + '/' + partners.image"
                alt=""
              />
            </div>
            <div class="box">
              <b-button
                @click="
                  (modalShow = !modalShow),
                    editPartners(partners._id, partners.show, partners.image)
                "
              >
                <i class="edit fal fa-pen"></i>
              </b-button>
            </div>
            <div class="box">
              <ConfirmDelete @clicktodelete="deletePartners(partners._id)" />
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal
      class="BMODAL"
      v-model="modalShow"
      id="modal-xl-2"
      size="xl"
      hide-footer
    >
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <div>
        <img
          style="height: 70px; width: 150px"
          :src="`${server_url}/${update.image}`"
        />
      </div>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        class="input"
        @change="uploadImage($event)"
      />
      <button class="submit" @click="UpdatePartners()">
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import AddForeignPartners from "../AddComponents/AddForeignPartners.vue";
import spinner from "../../spinner/Spinner.vue";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";
import { Container, Draggable } from "vue-smooth-dnd";
import Swal from "sweetalert2";

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;
  const result = [...arr];
  let itemToAdd = payload;
  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export default {
  name: "getPartners",
  components: {
    AddForeignPartners,
    VueEditor,
    Container,
    Draggable,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      modalShow: false,
      header: [
        this.$t("admin.active"),
        this.$t("admin.image"),
        this.$t("admin.edit"),
        this.$t("admin.delete"),
      ],
      allPartners: [],
      active_id: "",
      update: {
        show: false,
        image: null,
      },
      checkedItems: [],
      server_url: env.server_url,
      isLoading: false,
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    onDrop(dropResult) {
      this.allPartners = applyDrag(this.allPartners, dropResult);
      axios
        .post(`${env.host}/edit/full/partners`, this.allPartners)
        .then((res) => {
          console.log(res);
        });
    },
    getPartners() {
      this.isLoading = true;
      axios.get(`${env.host}/get/partners/admin`).then((res) => {
        this.isLoading = false;
        this.allPartners = res.data.item;
      });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      this.update.image = image;
    },
    editPartners(id, show, image) {
      this.active_id = id;
      this.update.show = show;
      this.update.image = image;
    },
    UpdatePartners() {
      this.isLoading = true;
      const formData = new FormData();
      if (typeof this.update.image != "object") {
        this.update.image = null;
      }
      formData.append("image", this.update.image);
      formData.append("show", this.update.show);
      axios
        .post(`${env.host}/edit/partners/${this.active_id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getPartners();
          }, 1000);
        });
    },
    deletePartners(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/partners/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.getPartners();
      });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.testingScroll {
  width: 100% !important;
  min-height: calc(100vh - 240px);

  max-height: calc(100vh - 220px) !important;
  overflow-y: scroll;
}
.BMODAL label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
.team_management {
  padding: 5px 10px 10px 10px;
  width: 100%;
}
.box {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #cccccc;
  align-items: center;
}
.main_div {
  display: flex;
  background: #f2f5fa;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 0px;
  align-items: center;
  padding: 0px !important;
  width: 100%;
}
.show_on_front {
  font-size: 30px;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.delete {
  background: #f02849;
}
.delete i,
.edit i {
  color: white;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
.input {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-left: 10px;
  height: 40px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
</style>
